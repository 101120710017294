import { TypeContact } from '../typeContact/TypeContact';

export class Contact {
  private contactId: number;
  private typeContact: TypeContact | null;
  private phone: string;
  private fax: string;
  private email: string;
  private address: string;
  private name: string;
  private mainContact: boolean;
  public constructor() {
    this.contactId = 0;
    this.typeContact = null;
    this.phone = '';
    this.fax = '';
    this.email = '';
    this.address = '';
    this.name = '';
    this.mainContact = false;
  }
}
